// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-за-нас-js": () => import("./../../../src/pages/за-нас.js" /* webpackChunkName: "component---src-pages-за-нас-js" */),
  "component---src-pages-контакти-js": () => import("./../../../src/pages/контакти.js" /* webpackChunkName: "component---src-pages-контакти-js" */),
  "component---src-pages-обекти-js": () => import("./../../../src/pages/обекти.js" /* webpackChunkName: "component---src-pages-обекти-js" */),
  "component---src-pages-услуги-js": () => import("./../../../src/pages/услуги.js" /* webpackChunkName: "component---src-pages-услуги-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

